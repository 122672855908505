@if (conditions.length && !isEditForm && !isDeletingCondition) {
  <section
    id="conditions-rules-list"
    data-testid="conditions-rules-list"
    class="conditions-rules-list"
  >
    @for (condition of conditions; track condition.rithmId; let i = $index) {
      <div class="conditions-rules-item">
        <p class="tracking-tight text-sm">
          {{
            condition.leftOperand.type === operandType.Relationship
              ? getLeftOperandRelationship(condition)
              : condition.leftOperand.questionType ===
                  questionFieldType.CustomId
                ? conditionHelper.getLeftOperandCustomIdType(condition)
                : condition.leftOperand.text
          }}
          @if (condition.leftOperand.type === operandType.Relationship) {
            {{
              condition.rightOperand.value === 'inward'
                ? '(inbound)'
                : '(outbound)'
            }}
            <b
              >{{
                conditionHelper.translateOperator(condition.operatorType)
              }}
              ({{
                condition.operatorType === operatorType.IsTrue
                  ? 'has related'
                  : 'has no related' + termsGeneric.Container.Plural
              }})</b
            >
          } @else {
            <b>{{
              conditionHelper.translateOperator(condition.operatorType)
            }}</b>
            {{
              condition.rightOperand.type === operandType.Date &&
              condition.rightOperand.text === condition.rightOperand.value
                ? (condition.rightOperand.value
                  | utcDateFormat: getSettingsDate(condition.leftOperand.value))
                : (condition.rightOperand.settings ===
                      questionFieldType.Duration ||
                      condition.rightOperand.settings ===
                        questionFieldType.TimeInStation) &&
                    condition.rightOperand.text === condition.rightOperand.value
                  ? (condition.rightOperand.value
                    | durationFieldFormat: durationSettingsJson)
                  : condition.rightOperand.text
            }}
          }
        </p>
        <button
          mat-icon-button
          type="button"
          [matMenuTriggerFor]="options_rule"
          class="text-lg"
          [attr.data-testid]="'option-condition-rule-' + i"
          [id]="'option-condition-rule-' + i"
          aria-label="option"
        >
          <i
            [ngClass]="orderOfOperations ? 'fa-light' : 'far'"
            class="far fa-ellipsis-h"
          ></i>
        </button>

        <mat-menu #options_rule="matMenu">
          <button
            mat-menu-item
            type="button"
            [attr.id]="'button-edit-condition-' + i"
            [attr.data-testid]="'button-edit-condition-' + i"
            (click)="editConditionForm(condition)"
          >
            <span class="p-4">Edit condition</span>
          </button>
          <button
            mat-menu-item
            type="button"
            [attr.id]="'button-delete-condition-' + i"
            [attr.data-testid]="'button-delete-condition-' + i"
            (click)="confirmRemoveCondition(i, condition.rithmId)"
          >
            <span class="p-4">Delete condition</span>
          </button>
        </mat-menu>
      </div>
    }
  </section>
}
@if (isDeletingCondition) {
  <div
    class="w-full h-14 flex border-b border-secondary-50 justify-center items-center"
  >
    <ng-container
      [ngTemplateOutlet]="loadingInlineText"
      [ngTemplateOutletContext]="{ $implicit: 'Deleting condition...' }"
    />
  </div>
}

<div
  class="app-conditions w-full px-8"
  [ngClass]="{
    'border-b border-secondary-50':
      orderOfOperations && openFormCondition && !showButtonNewCondition,
    'py-6': !orderOfOperations || openFormCondition
  }"
>
  @if (!openFormCondition || showButtonNewCondition) {
    <section class="conditions-with-no-rules">
      @if (!conditions.length) {
        <div
          class="condition-view-preview"
          [ngClass]="{ hidden: conditionsCount > 0 }"
        >
          <div class="condition-view-preview-icon flex justify-center">
            <i
              class="fa-solid fa-clipboard-list fa-3x"
              [ngClass]="{
                'text-sky-500': orderOfOperations,
                'text-accent-500': !orderOfOperations
              }"
            ></i>
          </div>
          <div class="condition-view-preview-description flex justify-center">
            <p class="w-1/2 lg:max-w-2xl text-center text-sm py-4">
              Conditions check the value saved on the
              {{ termsGeneric.Container.Single.toLowerCase() }} the trigger
              actions
            </p>
          </div>
        </div>
      }

      @if ((orderOfOperations && !conditions.length) || !orderOfOperations) {
        <div
          class="button-add-condition flex"
          [ngClass]="[
            conditions.length || conditionsCount > 0
              ? 'justify-start'
              : 'justify-center'
          ]"
        >
          <button
            mat-stroked-button
            type="button"
            data-testid="add-condition-rule"
            color="accent"
            [ngClass]="{
              '!bg-sky-200': !stationQuestionPool.length && orderOfOperations,
              '!bg-sky-500': stationQuestionPool.length && orderOfOperations,
              '!text-white': orderOfOperations
            }"
            (click)="
              openFormCondition = true;
              showButtonNewCondition = false;
              resetValues()
            "
            [disabled]="!stationQuestionPool.length"
          >
            @if (orderOfOperations) {
              <i class="fa-light fa-plus text-lg"></i> CONDITION
            } @else {
              Add Condition
            }
          </button>
        </div>
      }
      @if (!stationQuestionPool.length) {
        <div class="flex justify-center pt-3">
          <span class="text-xs text-center text-error-A400"
            >Questions must be added in the
            {{ termsGeneric.Station.Single | lowercase }} grid in order to add
            conditions.</span
          >
        </div>
      }
    </section>
  } @else {
    <ng-container [ngTemplateOutlet]="displayFormCondition" />
  }

  <ng-template #displayFormCondition>
    <section class="add-condition-form flex-col" id="add-condition-form">
      <!-- first operand question list -->
      @if (
        firstOperandQuestionList.length || firstOperandContainerInfoQuestion
      ) {
        <section class="first-operand-section flex">
          <mat-form-field
            appearance="outline"
            class="w-full"
            subscriptSizing="dynamic"
          >
            <mat-label class="text-base"> Field Name </mat-label>
            <mat-select
              id="first-operand-selector"
              data-testid="first-operand-selector"
              [(value)]="firstOperandQuestionRithmId"
            >
              <app-ngx-search
                [staticDataFields]="staticQuestions"
                [stationLibraryRelationships]="stationLibraryRelationships"
                [multipleQuestions]="[
                  firstOperandContainerInfoQuestion,
                  firstOperandQuestionList
                ]"
                (getFilteredArrays)="fieldToUpdateDataFiltered = $event"
              />

              @if (!fieldToUpdateDataFiltered.noMatchElements) {
                <!-- Station info questions. -->
                <mat-optgroup [label]="termsGeneric.Station.Single + ' Info'">
                  @if (
                    fieldToUpdateDataFiltered.staticDataFields?.includes(
                      staticQuestions[0]
                    )
                  ) {
                    <mat-option
                      [attr.data-testid]="'container-count-option'"
                      [value]="questionFieldType.ContainerCount"
                      (click)="
                        setFirstOperandInformation(
                          undefined,
                          undefined,
                          questionFieldType.ContainerCount
                        )
                      "
                    >
                      {{ termsGeneric.Container.Single }} Count
                    </mat-option>
                  }
                </mat-optgroup>
                <!-- Container info questions. -->
                @if (
                  fieldToUpdateDataFiltered.multipleQuestions &&
                  fieldToUpdateDataFiltered.multipleQuestions[0]
                ) {
                  <mat-optgroup
                    [label]="termsGeneric.Container.Single + ' Info'"
                  >
                    @if (
                      assignedToConditions &&
                      fieldToUpdateDataFiltered.staticDataFields?.includes(
                        staticQuestions[1]
                      )
                    ) {
                      <mat-option
                        [attr.data-testid]="'assigned-to-option'"
                        [value]="questionFieldType.AssignedUser"
                        (click)="
                          setFirstOperandInformation(
                            undefined,
                            undefined,
                            questionFieldType.AssignedUser
                          )
                        "
                      >
                        Assigned to
                      </mat-option>
                    }
                    @for (
                      question of fieldToUpdateDataFiltered
                        .multipleQuestions[0];
                      track question.rithmId
                    ) {
                      <mat-option
                        [attr.data-testid]="
                          'first-operand-option-' + question.rithmId
                        "
                        [value]="question.rithmId"
                        [disabled]="!firstOperandContainerInfoQuestion.length"
                        (click)="setFirstOperandInformation(question)"
                      >
                        {{ question.prompt }}
                      </mat-option>
                    }
                  </mat-optgroup>
                }
                <!-- Common questions. -->
                @if (
                  fieldToUpdateDataFiltered.multipleQuestions &&
                  fieldToUpdateDataFiltered.multipleQuestions[1]
                ) {
                  <mat-optgroup [label]="'Fields'">
                    @for (
                      question of fieldToUpdateDataFiltered
                        .multipleQuestions[1];
                      track question.rithmId
                    ) {
                      <ng-container>
                        <!-- Custom-Id question. -->
                        @if (
                          question.questionType === questionFieldType.CustomId
                        ) {
                          <mat-option
                            [attr.data-testid]="
                              'first-operand-option-' +
                              question.rithmId +
                              '-prefix'
                            "
                            [value]="question.rithmId + '-prefix'"
                            [disabled]="!firstOperandQuestionList.length"
                            (click)="
                              setFirstOperandInformation(
                                question,
                                undefined,
                                undefined,
                                'prefix'
                              )
                            "
                          >
                            {{ question.prompt + ' (prefix)' }}
                          </mat-option>
                          <mat-option
                            [attr.data-testid]="
                              'first-operand-option-' +
                              question.rithmId +
                              '-suffix'
                            "
                            [value]="question.rithmId + '-suffix'"
                            [disabled]="!firstOperandQuestionList.length"
                            (click)="
                              setFirstOperandInformation(
                                question,
                                undefined,
                                undefined,
                                'suffix'
                              )
                            "
                          >
                            {{ question.prompt + ' (suffix)' }}
                          </mat-option>
                        } @else {
                          <!-- Any question other than Custom-Id. -->
                          <mat-option
                            [attr.data-testid]="
                              'first-operand-option-' + question.rithmId
                            "
                            [value]="question.rithmId"
                            [disabled]="!firstOperandQuestionList.length"
                            (click)="setFirstOperandInformation(question)"
                          >
                            {{ question.prompt }}
                          </mat-option>
                        }
                      </ng-container>
                    }
                  </mat-optgroup>
                }
                <!-- Library relationship questions. -->
                @for (
                  library of fieldToUpdateDataFiltered.stationLibraryRelationship;
                  track library.rithmId
                ) {
                  @for (
                    relation of library.relationships;
                    track relation.rithmId
                  ) {
                    <mat-optgroup [label]="relation.name + ' (relationship)'">
                      @if (relation.inwardName) {
                        <mat-option
                          [value]="relation.rithmId + '-in'"
                          [attr.data-testid]="relation.rithmId + '-inwardName'"
                          [attr.id]="relation.rithmId + '-inwardName'"
                          (click)="
                            setFirstOperandInformation(relation, 'inwardName')
                          "
                        >
                          {{ relation.inwardName }}
                          <span class="text-primary-200"> (inbound)</span>
                        </mat-option>
                      }
                      @if (relation.outwardName) {
                        <mat-option
                          [value]="relation.rithmId + '-out'"
                          [attr.data-testid]="relation.rithmId + '-outwardName'"
                          [attr.id]="relation.rithmId + '-outwardName'"
                          (click)="
                            setFirstOperandInformation(relation, 'outwardName')
                          "
                        >
                          {{ relation.outwardName }}
                          <span class="text-primary-200"> (outbound)</span>
                        </mat-option>
                      }
                    </mat-optgroup>
                  }
                }
              }
            </mat-select>
          </mat-form-field>
        </section>
      } @else {
        <ng-container
          [ngTemplateOutlet]="loadingInlineText"
          [ngTemplateOutletContext]="{ $implicit: 'Loading Question List...' }"
        />
      }
      <!-- comparison type -->
      @if (
        firstOperandQuestionList.length || firstOperandContainerInfoQuestion
      ) {
        <section class="comparison-type-section flex">
          <!-- operators list -->
          <mat-form-field
            appearance="outline"
            class="w-full"
            subscriptSizing="dynamic"
          >
            <mat-label class="text-base"> Comparison Type</mat-label>
            <mat-select
              #operatorSelect
              id="operator-selector"
              data-testid="operator-selector"
              [disabled]="!firstOperandQuestionRithmId"
              [(value)]="operatorSelected"
              (selectionChange)="resetQuestionFieldComponent(true)"
            >
              @for (operator of operatorList; track operator.value) {
                <mat-option
                  id="operator-option-{{ operator.value }}"
                  attr.data-testid="operator-option-{{ operator.value }}"
                  [value]="operator.value"
                >
                  {{ operator.text }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </section>
      }
      <!-- second operand section -->
      @if (
        operatorSelected &&
        operatorSelected !== operatorType.IsAnswered &&
        operatorSelected !== operatorType.NotAnswered &&
        operatorSelected !== operatorType.IsTrue &&
        operatorSelected !== operatorType.IsFalse
      ) {
        <section
          class="second-operand-section flex space-x-2"
          data-testid="second-operand-section"
          id="second-operand-section"
        >
          @if (!isCustomField) {
            <section class="second-operand-bucket-fields w-full flex">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label class="text-base"> Bucket Fields </mat-label>
                <mat-select
                  id="second-operand-selector"
                  data-testid="second-operand-selector"
                  [disabled]="!bucketFieldsSecondOperand.length"
                  [(value)]="secondOperand.value"
                  (focus)="
                    bucketFieldsSecondOperand.length &&
                      resetQuestionFieldComponent()
                  "
                >
                  <app-ngx-search
                    [questions]="bucketFieldsSecondOperand"
                    (getFilteredArrays)="
                      filteredQuestionsForBucket = $event.questions || []
                    "
                  />
                  @for (
                    question of filteredQuestionsForBucket;
                    track question.rithmId
                  ) {
                    <mat-option
                      id="second-operand-option-{{ question.rithmId }}"
                      attr.data-testid="second-operand-option-{{
                        question.rithmId
                      }}"
                      [value]="question.rithmId"
                      (click)="setSecondOperandInformation(question)"
                    >
                      {{ question.prompt }}
                    </mat-option>
                  }
                </mat-select>
                @if (!bucketFieldsSecondOperand.length) {
                  <mat-hint
                    >There are not compatible fields in the bucket</mat-hint
                  >
                }
              </mat-form-field>
            </section>
          } @else {
            <div
              class="second-operand-custom-fields w-full flex"
              disabled
              [formGroup]="formSecondOperand"
            >
              @switch (customTypeForSecondOperand) {
                @case ('string') {
                  <app-text-field
                    #textField
                    [disabledQuestion]="
                      secondOperandDefaultQuestion.questionType ===
                      questionFieldType.CreatedBy
                    "
                    [field]="secondOperandDefaultQuestion"
                    [labelTag]="secondOperandDefaultQuestion.prompt"
                    [isStation]="false"
                    [isCondition]="true"
                    [isInput]="true"
                    [validateMask]="validateFormat"
                    [matTooltipDisabled]="
                      secondOperandDefaultQuestion.questionType !==
                      questionFieldType.CreatedBy
                    "
                    formControlName="textFieldForm"
                    class="w-full"
                    matTooltip="Coming Soon..."
                    matTooltipPosition="above"
                  />
                }
                @case ('number') {
                  <app-number-field
                    #numberField
                    [field]="secondOperandDefaultQuestion"
                    [validateMask]="validateFormat"
                    formControlName="numberFieldForm"
                    class="w-full"
                  />
                }
                @case ('date') {
                  <app-date-field
                    #dateField
                    [field]="secondOperandDefaultQuestion"
                    formControlName="dateFieldForm"
                    class="w-full"
                  />
                }
                @case ('select') {
                  <app-select-field
                    #selectField
                    formControlName="selectFieldForm"
                    [stationId]="stationRithmId"
                    [field]="secondOperandDefaultQuestion"
                    [isCondition]="true"
                    class="w-full"
                  />
                }
                @case ('multiselect') {
                  <app-select-field
                    #selectField
                    [stationId]="stationRithmId"
                    [field]="secondOperandDefaultQuestion"
                    formControlName="selectFieldForm"
                    class="w-full"
                  />
                }
                @case ('checklist') {
                  <app-select-field
                    #selectField
                    formControlName="checkFieldForm"
                    [stationId]="stationRithmId"
                    [field]="secondOperandDefaultQuestion"
                    class="w-full"
                  />
                }
                @case ('duration') {
                  <app-duration-field
                    #durationField
                    [field]="secondOperandDefaultQuestion"
                    [stationId]="stationRithmId"
                    [isCondition]="true"
                    formControlName="durationFieldForm"
                    class="w-full"
                  />
                }
                @case ('userSelect') {
                  <div class="w-full">
                    @if (!isLoadingUser && !isErrorUser) {
                      <app-select-field
                        #selectField
                        formControlName="checkFieldForm"
                        [field]="secondOperandDefaultQuestion"
                        [stationId]="stationRithmId"
                        class="w-full"
                      />
                    }
                    @if (isLoadingUser) {
                      <div
                        class="w-full h-full items-center flex justify-center"
                      >
                        <app-loading-indicator inlineText="Loading..." />
                      </div>
                    }
                  </div>
                }
                @case ('assignedUser') {
                  <div class="w-full">
                    @if (
                      !isLoadingUser &&
                      !isErrorUser &&
                      stationQuestionPool &&
                      stationQuestionPool.length
                    ) {
                      <app-user-relates
                        #userRelates
                        [stationId]="stationRithmId"
                        [questionSelect]="stationQuestionPool"
                        [field]="secondOperandDefaultQuestion"
                        [isEditForm]="isEditForm"
                        class="w-full"
                      />
                    }
                  </div>
                }

                @default {
                  <app-text-field
                    #textField
                    [field]="secondOperandDefaultQuestion"
                    [labelTag]="secondOperandDefaultQuestion.prompt"
                    [isStation]="true"
                    [isInput]="true"
                    formControlName="textFieldForm"
                    class="w-full"
                  />
                }
              }
            </div>
          }
          <button
            mat-stroked-button
            type="button"
            (click)="isCustomField = !isCustomField; resetValuesSecondOperand()"
            data-testid="button-switch-condition"
            id="button-switch-condition"
            [disabled]="disableCustomFieldControl"
          >
            <i
              class="fa-xl"
              [ngClass]="{
                'fa-angle-down': isCustomField,
                'fa-keyboard': !isCustomField,
                'fa-solid text-accent-500': !orderOfOperations,
                'fa-light text-sky-500': orderOfOperations,
                'text-secondary-200': disableCustomFieldControl
              }"
            ></i>
          </button>
        </section>
      }
      @if (
        firstOperandQuestionList.length || firstOperandContainerInfoQuestion
      ) {
        <div class="condition-control-buttons space-x-2">
          <button
            mat-button
            type="button"
            data-testid="condition-cancel"
            id="condition-cancel"
            (click)="cancelCondition()"
            [disabled]="isLoadingCondition"
          >
            Cancel
          </button>
          <button
            type="button"
            [id]="isEditForm ? 'save-changes-button' : 'condition-save'"
            mat-button
            color="accent"
            [ngClass]="{
              'animate-pulse': isLoadingUser,
              '!bg-sky-500': !disabledButtonSave && orderOfOperations,
              '!bg-sky-200': disabledButtonSave && orderOfOperations,
              '!text-white': orderOfOperations
            }"
            [attr.data-testid]="
              isEditForm ? 'save-changes-button' : 'condition-save'
            "
            [disabled]="disabledButtonSave || isLoadingCondition"
            (click)="
              isEditForm
                ? orderOfOperations
                  ? addOrUpdateCondition()
                  : saveChanges()
                : orderOfOperations
                  ? addOrUpdateCondition()
                  : setEquationContent()
            "
          >
            @if (!isLoadingCondition) {
              {{
                isEditForm
                  ? orderOfOperations
                    ? 'Save changes'
                    : 'Save'
                  : orderOfOperations
                    ? 'Save'
                    : 'Add'
              }}
            } @else {
              <ng-container
                [ngTemplateOutlet]="loadingInlineText"
                [ngTemplateOutletContext]="{ $implicit: 'Saving...' }"
              />
            }
          </button>
        </div>
      }
    </section>
  </ng-template>
</div>

<!-- Loading question list. -->
<ng-template #loadingInlineText let-message>
  <app-loading-indicator id="loading-inline-text" [inlineText]="message" />
</ng-template>
